import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    meta: {
      appFrame: false,
      navBar: false,
      basePath: true,
    },
    component: login
  },
  {
    path: '/events',
    name: 'events',
    meta: {
      appFrame: true,
      titlepage: "Eventos",
      navBar: true,
      allowedRoles:["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/Eventos.vue')
  },
  {
    path: '/general',
    name: 'general',
    meta: {
      appFrame: true,
      titlepage: "General",
      navBar: true,
      allowedRoles:["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/General.vue')
  },
  {
    path: '/location',
    name: 'location',
    meta: {
      appFrame: true,
      titlepage: "Ubicación del evento",
      navBar: true,
      allowedRoles:["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/location.vue')
  },
  {
    path: '/gallery',
    name: 'gallery',
    meta: {
      appFrame: true,
      titlepage: "Galería",
      navBar: true,
      allowedRoles:["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/gallery.vue')
  },
  {
    path: '/map',
    name: 'map',
    meta: {
      appFrame: true,
      titlepage: "Mapa del evento",
      navBar: true,
      allowedRoles:["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/map.vue')
  },
  {
    path: '/sections',
    name: 'sections',
    meta: {
      appFrame: true,
      titlepage: "Secciones",
      navBar: true,
      allowedRoles:["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/sections.vue')
  },
  {
    path: '/FunctionSchedules',
    name: 'FunctionSchedules',
    meta: {
      appFrame: true,
      titlepage: "Horarios de funciones",
      navBar: true,
      allowedRoles:["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/FunctionSchedules.vue')
  },
  {
    path: '/customForm',
    name: 'customForm',
    meta: {
      appFrame: true,
      titlepage: "Formulario Adicional",
      navBar: true,
      allowedRoles:["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/customForm.vue')
  },
  {
    path: '/tickets',
    name: 'tickets',
    meta: {
      appFrame: true,
      titlepage: "Entradas de funciones",
      navBar: true,
      allowedRoles:["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/tickets.vue')
  },
  {
    path: '/ticketsSold',
    name: 'ticketsSold',
    meta: {
      appFrame: true,
      titlepage: "Boletos Vendidos",
      navBar: true,
      allowedRoles:["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/ticketsSold.vue')
  },
  {
    path: '/BulkLoad',
    name: 'BulkLoad',
    meta: {
      appFrame: true,
      titlepage: "Carga masiva",
      navBar: true,
      allowedRoles:["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/BulkLoadV2.vue')
  },
  {
    path: '/userScanner',
    name: 'userScanner',
    meta: {
      appFrame: true,
      titlepage: "Usuarios Scanner",
      navBar: true,
      allowedRoles:["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/userScanner.vue')
  },
  {
    path: '/sudo/business',
    name: 'business',
    meta: {
      appFrame: true,
      titlepage: "Comercios",
      navBar: true,
      allowedRoles: ["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/sudo/businesses/businesses.vue')
  },
  {
    path: '/sudo/users',
    name: 'users',
    meta: {
      appFrame: true,
      titlepage: "Socios Comerciales",
      navBar: true,
      allowedRoles: ["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/sudo/users.vue')
  },
  {
    path: "/special-users",
    name: "specialUser",
    meta: {
      appFrame: true,
      titlepage: "Usuarios especiales",
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () =>
      import( "../views/sudo/specialUsers.vue"),
  },
  {
    path: '/sudo/payment-gateways',
    name: 'payment-gateways',
    meta: {
      appFrame: true,
      titlepage: "Métodos de pagos ",
      navBar: true,
      allowedRoles: ["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/sudo/payment-gateways/payment-gateways.vue')
  },
  {
    path: '/sudo/profile',
    name: 'profile',
    meta: {
      appFrame: true,
      titlepage: "Configuración",
      navBar: true,
      allowedRoles: ["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/sudo/settings/profile.vue')
  },
  {
    path: '/sudo/cities',
    name: 'cities',
    meta: {
      appFrame: true,
      titlepage: "Ciudades",
      navBar: true,
      allowedRoles: ["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/sudo/cities/cities.vue')
  },
  {
    path: '/sudo/categories',
    name: 'categories',
    meta: {
      appFrame: true,
      titlepage: "Categorias",
      navBar: true,
      allowedRoles: ["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/sudo/categories/categories.vue')
  },
  {
    path: '/sudo/bannerapp',
    name: 'Banner App',
    meta: {
      appFrame: true,
      titlepage: "Banners vive app",
      navBar: true,
      allowedRoles: ["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/sudo/bannerApp.vue')
  },
  {
    path: '/sudo/tags',
    name: 'tags',
    meta: {
      appFrame: true,
      titlepage: "Etiquetas",
      navBar: true,
      allowedRoles: ["sudo",'viveSudo',"viveLesserAdmin","viveFinance","viveOperations",'viveSales',"viveBusiness"],
    },
    component: () => import( '../views/sudo/tags/tags.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
